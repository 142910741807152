.highlighter-rouge {
  position: relative;
}
.highlighter-rouge::before {
  position: absolute;
  z-index: 1;
  padding: 5px 10px;
  background: $syntax-lang-color;
  right: -5px;
  top: -5px;
  border-radius: 4px;
  font-size: 0.85em;
  font-weight: bold;
  opacity: 0.75;
}
.highlighter-rouge.language-js::before {
  content: 'JS';
}
.highlighter-rouge.language-html::before {
  content: 'HTML';
}
.highlighter-rouge.language-css::before {
  content: 'CSS';
}
.highlighter-rouge.language-scss::before {
  content: 'SCSS';
}
.highlighter-rouge.language-ruby::before {
  content: 'Ruby';
}


.highlight .hll { background-color: #ffffcc }
.highlight  { background: #ffffff; }
.highlight .c { color: #888888 } /* Comment */
.highlight .err { color: #FF0000; background-color: #FFAAAA } /* Error */
.highlight .k { color: #008800; font-weight: normal } /* Keyword */
.highlight .o { color: #333333 } /* Operator */
.highlight .ch { color: #888888 } /* Comment.Hashbang */
.highlight .cm { color: #888888 } /* Comment.Multiline */
.highlight .cp { color: #557799 } /* Comment.Preproc */
.highlight .cpf { color: #888888 } /* Comment.PreprocFile */
.highlight .c1 { color: #888888 } /* Comment.Single */
.highlight .cs { color: #cc0000; font-weight: normal } /* Comment.Special */
.highlight .gd { color: #A00000 } /* Generic.Deleted */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gr { color: #FF0000 } /* Generic.Error */
.highlight .gh { color: #000080; font-weight: normal } /* Generic.Heading */
.highlight .gi { color: #00A000 } /* Generic.Inserted */
.highlight .go { color: #888888 } /* Generic.Output */
.highlight .gp { color: #c65d09; font-weight: normal } /* Generic.Prompt */
.highlight .gs { font-weight: normal } /* Generic.Strong */
.highlight .gu { color: #800080; font-weight: normal } /* Generic.Subheading */
.highlight .gt { color: #0044DD } /* Generic.Traceback */
.highlight .kc { color: #008800; font-weight: normal } /* Keyword.Constant */
.highlight .kd { color: #008800; font-weight: normal } /* Keyword.Declaration */
.highlight .kn { color: #008800; font-weight: normal } /* Keyword.Namespace */
.highlight .kp { color: #003388; font-weight: normal } /* Keyword.Pseudo */
.highlight .kr { color: #008800; font-weight: normal } /* Keyword.Reserved */
.highlight .kt { color: #333399; font-weight: normal } /* Keyword.Type */
.highlight .m { color: #6600EE; font-weight: normal } /* Literal.Number */
/*.highlight .s { background-color: #fff0f0 }  Literal.String */
.highlight .na { color: #0000CC } /* Name.Attribute */
.highlight .nb { color: #007020 } /* Name.Builtin */
.highlight .nc { color: #BB0066; font-weight: normal } /* Name.Class */
.highlight .no { color: #003366; font-weight: normal } /* Name.Constant */
.highlight .nd { color: #555555; font-weight: normal } /* Name.Decorator */
.highlight .ni { color: #880000; font-weight: normal } /* Name.Entity */
.highlight .ne { color: #FF0000; font-weight: normal } /* Name.Exception */
.highlight .nf { color: #0066BB; font-weight: normal } /* Name.Function */
.highlight .nl { color: #997700; font-weight: normal } /* Name.Label */
.highlight .nn { color: #0e84b5; font-weight: normal } /* Name.Namespace */
.highlight .nt { color: #007700 } /* Name.Tag */
.highlight .nv { color: #996633 } /* Name.Variable */
.highlight .ow { color: #000000; font-weight: normal } /* Operator.Word */
.highlight .w { color: #bbbbbb } /* Text.Whitespace */
.highlight .mb { color: #6600EE; font-weight: normal } /* Literal.Number.Bin */
.highlight .mf { color: #6600EE; font-weight: normal } /* Literal.Number.Float */
.highlight .mh { color: #005588; font-weight: normal } /* Literal.Number.Hex */
.highlight .mi { color: #0000DD; font-weight: normal } /* Literal.Number.Integer */
.highlight .mo { color: #4400EE; font-weight: normal } /* Literal.Number.Oct */
/*.highlight .sa { background-color: #fff0f0 }  Literal.String.Affix */
/*.highlight .sb { background-color: #fff0f0 }  Literal.String.Backtick */
.highlight .sc, .highlight .s, .highlight .s1, .highlight .s2 { color: #883300 } /* Literal.String.Char */
/*.highlight .dl { background-color: #fff0f0 }  Literal.String.Delimiter */
.highlight .sd { color: #DD4422 } /* Literal.String.Doc */
/*.highlight .s2 { background-color: #fff0f0 }  Literal.String.Double */
.highlight .se { color: #666666; font-weight: normal; background-color: #fff0f0 } /* Literal.String.Escape */
.highlight .sh { background-color: #fff0f0 } /* Literal.String.Heredoc */
.highlight .si { background-color: #eeeeee } /* Literal.String.Interpol */
.highlight .sx { color: #DD2200; background-color: #fff0f0 } /* Literal.String.Other */
.highlight .sr { color: #333399; } /* Literal.String.Regex */
/*.highlight .s1 { background-color: #fff0f0 }  Literal.String.Single */
.highlight .ss { color: #AA6600 } /* Literal.String.Symbol */
.highlight .bp { color: #007020 } /* Name.Builtin.Pseudo */
.highlight .fm { color: #0066BB; font-weight: normal } /* Name.Function.Magic */
.highlight .vc { color: #336699 } /* Name.Variable.Class */
.highlight .vg { color: #dd7700; font-weight: normal } /* Name.Variable.Global */
.highlight .vi { color: #3333BB } /* Name.Variable.Instance */
.highlight .vm { color: #996633 } /* Name.Variable.Magic */
.highlight .il { color: #0000DD; font-weight: normal } /* Literal.Number.Integer.Long */
