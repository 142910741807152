:not(.title, .tag, .has-primary-links) > a:not(.button) {
  text-decoration: underline;
  text-underline-offset: .15ch;
  text-decoration-color: rgb(20, 65, 120);
  font-weight: 600;

  &:hover {
    text-decoration-color: var(--color-secondary);
  }
}

.title > :where(a), layout-columns :where(.has-primary-links > a) {
  color: var(--color-primary);
}

.subtitle {
  font-weight: 600;
}

.content {
  pre {
    border: $code-border;
    background: $code-background;
    margin-bottom: 2em;
  }
  p,
  dl,
  ol,
  ul,
  blockquote,
  table {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
  p > code, li > code {
    word-wrap: break-word;
  }
}

.e-content.column {
  @include desktop {
    margin-right: 1.5rem;
  }
}
aside.column {
  @include desktop {
    padding-right: 1.5rem;
  }
  @include fullhd {
    padding-right: 0;
  }
}