nav.navbar {
  box-shadow: 0px 10px 50px rgba(0,0,0,0.7);
}

.navbar-brand .logo {
  img {
    margin-top: -4px;
    width: 200px;
    filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.35));
    margin-left: 0.3rem;
    margin-right: 1rem;
  }
  font-family: "titling-gothic-fb", sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  color: #ddd !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  span {
    margin-left: -0.1em;
    font-weight: 700;
    color: white;
  }
}
.navbar-item {
  font-weight: 700;
}
main.section {
  padding-top: 0;
}
.has-dropdown {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

footer.footer {
  margin-top: 8rem;
  
  .logogram {
    width: 175px;
    margin-left: 3rem;
  }
}

.button {
  font-weight: 600;
}
.button.is-primary.is-outlined {
  border: none;
  border-color: transparent;
  box-shadow: -15px -15px 20px rgba(255,255,255,0.03),0px -10px 10px rgba(255,255,255,0.05),15px 15px 20px rgba(0,0,0,0.15),0px 10px 10px rgba(0,0,0,0.1),-3px -3px 4px rgba(255,255,255,0.05),3px 3px 4px rgba(0,0,0,0.1), inset 4px 4px 10px rgba(255,255,255,0.05), inset -4px -4px 10px rgba(0,0,0,0.05);
}
input.big-shadow {
  box-shadow: 15px 15px 20px rgba(255,255,255,0.03), 0px 10px 10px rgba(255,255,255,0.05), -15px -15px 20px rgba(0,0,0,0.15), 0px -10px 10px rgba(0,0,0,0.1), 3px 3px 4px rgba(255,255,255,0.05), -3px -3px 4px rgba(0,0,0,0.1);
  background: #032045;
  color: white;
}
.author img.is-rounded {
  box-shadow: -15px -15px 20px rgba(255,255,255,0.05),0px -10px 10px rgba(255,255,255,0.1),15px 15px 20px rgba(0,0,0,0.15),0px 10px 10px rgba(0,0,0,0.1),-3px -3px 4px rgba(255,255,255,0.1),3px 3px 4px rgba(0,0,0,0.1);
}

.input, .textarea, .select select {
  font-weight: 600;
}

.field.has-addons {
  .control:last-of-type {
    z-index: 4;
  }
}
