@charset "utf-8";

$radius: 12px;

$primary: #EB1458;
$secondary: #C8304E;
$darkest-blue: #010F21;
$darker-blue: #032045;
$dark-blue: #04254E;
$medium-blue: #144178;
$light-blue: #4BBFF4;
$lighter-blue: #8fcce8;
$vibrant-yellow: #FFDA23;
$yellow: #FFDA23;
$link: $light-blue;
$link-hover: saturate(lighten($primary, 12%), 50%);
$accent: #0D5F88;
.has-background-accent {
  background-color: $accent;
}
$success: #3adb76;
$warning: #ffae00;
$danger: #cc4b37;
$black: #0a0b0c;
$white: #ffffff;
$background: $darkest-blue;
$text: $lighter-blue;
$text-strong: $yellow;
$title-color: $white;
$subtitle-color: $secondary;
$grey-lighter: desaturate($light-blue, 23%);
$grey-light: lighten($accent, 16%);
$grey: lighten($accent, 8%);
$grey-dark: lighten($dark-blue, 5%);

$border: $grey-dark;

$body-background-color: $background;
body {
  background: linear-gradient(to bottom right, $black, $darkest-blue, darken($dark-blue, 5%) 75%, $secondary);
}
$content-background-color: $background;
$content-heading-color: $white;

$fullhd: 1500px;

$hr-height: 1px;

$callout-background: $dark-blue;
$callout-border: 1px solid $border;

$box-background-color: $callout-background;
$box-shadow: 30px 30px 69px rgba(3, 29, 61, 0.85), -30px -30px 69px rgba(3, 34, 71, 0.5);
.box {
  border: $callout-border;
}
$box-radius: 24px;
$card-background-color: $callout-background;
$card-header-color: $white;

$navbar-height: 5rem;
$navbar-item-img-max-height: 4rem;
$navbar-item-hover-color: $link-hover;
$navbar-item-hover-background-color: $grey-dark;
$navbar-background-color: $dark-blue;
$navbar-dropdown-background-color: $grey-dark;
$navbar-dropdown-item-hover-color: $primary;
$navbar-dropdown-border-top: none;
$navbar-divider-background-color: $grey;
$navbar-item-color: $link;
.navbar-burger:hover {
  color: white !important;
}
 
$footer-background-color: $darkest-blue;
footer.footer {
  background: linear-gradient(to bottom right, $black, lighten($darkest-blue, 3%));
  box-shadow: 0px -10px 50px rgba(3, 29, 61, 0.7);
}

$syntax-lang-color: #ffc;
$code-border: 0.5px dashed #9ca;
$code-background: #f9f9f9;
$content-pre-padding: 1em 1.1em;

$input-color: darken($secondary, 10%);
$input-border-color: transparent;
$input-hover-border-color: transparent;
$input-focus-border-color: $yellow;
$input-placeholder-color: $grey-light;
$input-icon-color: $grey;
$input-icon-active-color: $input-focus-border-color;

$body-size: 18px;
$family-sans-serif: "Passenger Sans W01", sans-serif;
$title-family: "TT Supermolot Neue W03", sans-serif;

$spacing-shortcuts: ("margin": "bl-m", "padding": "bl-p");
$spacing-values: (
  "0": 0,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 0.75rem,
  "4": 1rem,
  "5": 1.5rem,
  "6": 2rem,
  "7": 3rem,
  "8": 4rem
);

:root {
  --color-primary: #{$primary};
  --color-primary-hover: #ff3d7a;
  --color-dark-primary-hover: #df1354;
  --color-secondary: #{$secondary};
  --color-darkest-blue: #{$darkest-blue};
  --color-darker-blue: #{$darker-blue};
  --color-dark-blue: #{$dark-blue};
  --color-medium-blue: #{$medium-blue};
  --color-light-blue: #{$light-blue};
  --color-link: #{$link};
  --color-link-hover: #{$link-hover};
  --color-accent: #{$accent};
  --color-vibrant-yellow: #{$vibrant-yellow};
  --color-yellow: #{$yellow};
  
  --radius: #{$radius};
  --box-radius: #{$box-radius};
  --callout-border: #{$callout-border};


  @each $name, $value in $spacing-values {
    --spacing-#{$name}: #{$value};
  }

  --title-size: var(--spacing-7);

  @media (max-width: 1023px) {
    --title-size: calc(var(--spacing-6) + var(--spacing-1));
  }
}

/*******************/
/* Bulma Utilities */
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/helpers/_all.sass";
@import "~bulma/sass/layout/_all.sass";

/* Bulma Elements */
@import "~bulma/sass/elements/box.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/icon.sass";
@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/elements/tag.sass";
@import "~bulma/sass/elements/title.sass";

/* Bulma Components */
@import "~bulma/sass/components/breadcrumb.sass";
@import "~bulma/sass/components/media.sass";
@import "~bulma/sass/components/navbar.sass";

/*****************/
/* Custom Styles */
@import "layout.scss";
@import "content.scss";
@import "syntax.scss";
@import "pages.scss";
